body{
  background: #ffffff!important;
}
.site_header_logo {
  width: 209px;
}
.banner_header_overlay {
  background: #0000003b;
}

.starter_banner_caption {
  border-radius: 10px;
}
.service-item .icon img {
  border-radius: 50%;
}
.image_container_row {
  list-style: none;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  margin: 10px;
}

.image_container_row .left-image {
  padding: 0px;
}
.background-header .main-nav .nav li a{
  cursor: pointer;
}

.image_container_row .right-content {
  padding: 32px;
}

.image_container_row .left-image img {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.image_container_row .right-content h4 {
  /* text-align: left; */
  font-size: 20px;
  color: #111;
  margin-bottom: 25px;
  font-weight: 800;
  /* margin-right: 380px; */
}

.App {
  position: relative;
}

.tap-animation {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 99999;
  background-color: rgba(0, 150, 255, 0.5);
  border-radius: 50%;
  animation: tap-pulse 0.5s ease-out;
  pointer-events: none; /* Disable interactions */
}
ul.nav {
  background: #f7f7f7;
}
.header-area.disabled_click {
  -webkit-user-select: none; /* Disable text selection for Safari */
  user-select: none; /* Disable text selection for other browsers */
  pointer-events: none; /* Disable all mouse interactions */
}
@keyframes tap-pulse {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
